export const environment = {
  production: false,
  baseURL: "https://testapi.mangomirror.com/v1.0.5/",
  // baseURL: "http://localhost:8080/mangomirror/v1.0.5/",
  portalBaseURL: "https://testportal.mangodisplay.com/",
  // portalBaseURL: "http://localhost:8090/SmartMirrorLayout/",
  stripeKey: "pk_test_n3svlgY7O1cTtAhrnJf4YrDz00AhOxpaeb",
  stripeYearlyPlanKey: "com.chakra.smartmirror.us.yearly",
  stripeMonthlyPlanKey: "com.chakra.smartmirror.us.monthly",
  gifKey: [
    "HV4474U524b4Gj3L8I5Ip7VHfxT201Qa",
    "riXTYC24uYL6ou1Z153ECc4XjXHtmAgH",
    "fUi1eHfL7rJ1a2qQ3wJPue8d1mxJlVEv",
    "PpHGv5XPyP2jcF4pK6hRtHP2UUFfGXZA",
    "mArNrrEA21ZkicxpNIk2K54qVTkgbdrw",
    "IzsNb9k5TeKM0BIGBGIz0ECqIuXowTVv",
    "u5KLQgvsjtSbPVSzNmHmIFjqFFzjm7KI",
    "ES9xJ0BjDjR3nd45wRur7TsMoEsZZQJr",
    "xACBywyBf6KFztFcNHkd8yjP7Gx9KdDq",
    "IJKXD19gCWsfOxdyw5r7KTwIbzvo98KB",
    "hTE1bvdeEmeoGmta44aOCTejwYvYhlnj",
    "KcaS8geUZvc1bE6Y2J7he1jG41qYcPmi",
    "DOoAfGqvUKfku0s4pe5DEDf9QHXJQDeL",
    "z6rFnTIsCCfETnntl7LxDnYoDvNVP7OS",
  ],
};
