import {
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "angular-web-storage";
import {
  autofitSupportWidgets,
  fontFormatLabel,
  Fonts,
  SupportedFontSize,
  text_alignment,
} from "src/app/util/static-data";

@Component({
  selector: "app-widget-bg-setting",
  templateUrl: "./widget-bg-setting.component.html",
  styleUrls: ["./widget-bg-setting.component.scss"],
})
export class WidgetBgSettingComponent
  implements OnInit, OnChanges, AfterViewInit
{
  // availableFontSize: any[] = SupportedFontSize;
  defaultFontSize: any[] = [
    { name: "auto fit", value: "default" },
    { name: "custom", value: "custom" },
  ];
  supportedCustomTitleNameModification: any[] = [
    "calendar",
    "todo",
    "count_down",
    "chores",
    "mealplan",
    "clock",
    "quotes",
    "notes",
    "stickynotes",
    "weather",
  ];
  supportedCustomFormatModification: any[] = [
    ...this.supportedCustomTitleNameModification,
    "news",
  ];

  bgSettingOptions: any = {
    transparency: 5,
    blur: 0,
    shadow: false,
    corner: "square",
    backgroundColor: "#2c82fd",
    isMirrorBackgroundSettingEnabled: true,
    id: "",
    widgetname: "",
    isNameVisible: false,
    titleFontSize: "smallest",
    fontSize: "smallest",

    fontFamily: "Open Sans",
    fontColor: "#212529",

    titleFontFamily: "Open Sans",
    titleFontColor: "#212529",
    titleBackgroundColor: "#FCFCFC",
    titleAlignment: "center",
    widgetTitleFormat: "",
    widgetFormat: "",
    autofit: false,
  };

  @Input() widgetType: any;
  @Input() subCategoryType: any = null;

  @Input() widgetbgsetting: any;
  @Output() emitbgsettingOptions: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitbgsettingNewsOptions: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingQuotesOptions: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingWeatherOptions: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingCalenderOptions: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitMirrorbgsettingOptions: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingNoteOptions: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingHealthOptions: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingImageOptions: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingVideoOption: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingGoogleMapOption: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingMicrosoftDocOption: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingGoogleDocOption: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingAsanaOption: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingAirtableOption: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingTrelloOption: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingEmbedWebsiteOption: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() emitbgsettingEmbedHtmlOption: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() closeModalEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitbgsettingPdfOption: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitbgsettingTodo: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitbgsettingCountDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitbgsettingChores: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitbgsettingMealPlanOptions: EventEmitter<any> =
    new EventEmitter<any>();

  backgroundEffect: string = "Transparency";
  titleFontFamilyOptions: boolean = false;
  fontFamilyOptions: boolean = false;
  dropdownStates: { [key: string]: boolean } = {};
  availableFontFamilies = [...Fonts];

  availableFontFormatLabel = [...fontFormatLabel];
  availableColorSelection = ["default", "custom"];
  availableFontSizeType = ["default", "custom"];
  selectedFontColor: string = "default";
  selectedBGFormat: string = "default";
  availableBGFormatSelection = ["default", "custom"];
  textAlignments = [...text_alignment];
  autofitSupportWidgets = [...autofitSupportWidgets];
  isDefaultBackgroundColor: boolean = false;

  // create function to filer availableFontFormatLabel based on category and subcategory if subcategory is available and assign the value to fontFormat object

  customFontSizeWidget = [
    "calendar",
    "todo",
    "count_down",
    "chores",
    "mealplan",
    "quotes",
  ];

  constructor(private eRef: ElementRef, private storage: LocalStorageService) {
    let defaultFontFamily = {
      id: 0,
      googleFontName: "default",
      googleFontsPath: "",
      fontCategory: "default",
    };
    this.availableFontFamilies.unshift(defaultFontFamily);
  }
  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  titleFormat = {
    fontFamily: "Open Sans",
    fontColor: "default",
    fontSize: 10,
    alignment: "Left",
    backgroundColor: "default",
  };

  widgetDefaultValues = {
    fontFamily: "default",
    fontSize: "16",
    alignment: "left",
    fontColor: "default",
  };

  fontFormat = [];

  // commonFontFormat: any;
  commonFontFormat: any = {};

  // Method to close dropdown if user clicks outside of the container
  @HostListener("document:click", ["$event"])
  closeDropdown(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdownContainer = document.querySelector(
      ".widget-bg-custom-select-container"
    );

    if (dropdownContainer != undefined && !dropdownContainer.contains(target)) {
      this.closeAllDropdowns();
    }
  }

  closeAllDropdowns(): void {
    for (const key in this.dropdownStates) {
      if (this.dropdownStates.hasOwnProperty(key)) {
        this.dropdownStates[key] = false;
      }
    }
  }

  ngOnInit() {}

  filterFontFormatLabel(widgetType: string, subcategory?: string): void {
    this.fontFormat = this.availableFontFormatLabel.filter((label) => {
      const isWidgetTypeMatch = label.widgetType.includes(widgetType);
      const isSubcategoryMatch = subcategory
        ? label.subcategory.some(
            (sub) => sub.toLowerCase() === subcategory.toLowerCase()
          )
        : true;
      return isWidgetTypeMatch && isSubcategoryMatch;
    });
  }

  setFontFamily(item: any, value: string, formatType: string): void {
    if (formatType == "widgetFormat") {
      this.commonFontFormat[item.prefix].fontFamily = value;
      this.dropdownStates[item.label] = false;
    } else {
      this.titleFormat.fontFamily = value;
      this.dropdownStates[item] = false;
    }
  }

  setFontSize(item: any, value: number): void {
    if (value < 4 || value > 800) {
      value = 16;
    }
    this.commonFontFormat[item.prefix].fontSize = value;
  }

  setFontAlignment(item: any, value: string, formatType: string): void {
    if (formatType == "widgetFormat") {
      this.commonFontFormat[item.prefix].alignment = value;
    } else {
      this.titleFormat.alignment = value;
    }
  }

  setFontColor(item: any, value: string): void {
    this.commonFontFormat[item.prefix].fontColor = value;
  }

  toggleOptions(event: Event, type: string): void {
    this.dropdownStates[type] = !this.dropdownStates[type];
    event.stopPropagation();
  }

  updateTitleBgColor() {
    this.isDefaultBackgroundColor = true;
  }

  ngOnChanges(changes: any) {
    if (changes.widgetType !== undefined) {
      if (changes.widgetType.currentValue !== undefined) {
        this.widgetType = changes.widgetType.currentValue;
      }
    }

    if (changes.subCategoryType !== undefined) {
      if (changes.subCategoryType.currentValue !== undefined) {
        this.subCategoryType = changes.subCategoryType.currentValue;
      }
    }

    if (changes.widgetbgsetting !== undefined) {
      if (changes.widgetbgsetting.currentValue !== undefined) {
        this.filterFontFormatLabel(this.widgetType, this.subCategoryType);
        this.bgSettingOptions = changes.widgetbgsetting.currentValue;

        let active_mirror = this.storage.get("activeMirrorDetails");
        let mirror_background_setting = active_mirror.mirror.backgroundSetting;

        this.titleFormat = JSON.parse(this.bgSettingOptions.widgetTitleFormat);
        if (this.titleFormat.fontColor == "default") {
          this.titleFormat.fontColor = mirror_background_setting.fontColor;
        }

        if (this.titleFormat.backgroundColor == "default") {
          this.isDefaultBackgroundColor = false;
          this.titleFormat.backgroundColor = "#ffffff";
        }

        if (this.titleFormat.fontFamily == "default") {
          this.titleFormat.fontFamily = mirror_background_setting.fontFamily;
        }
        this.commonFontFormat = JSON.parse(this.bgSettingOptions.widgetFormat);
        const dataKeys = Object.keys(this.commonFontFormat);

        // You can also iterate through the keys and log the values
        dataKeys.forEach((key) => {
          const dataKeys1 = Object.keys(this.commonFontFormat[key]);
          dataKeys1.forEach((key1) => {
            if (key1 == "fontFamily") {
              if (this.commonFontFormat[key][key1] == "default") {
                this.commonFontFormat[key][key1] =
                  mirror_background_setting.fontFamily;
              }
            } else if (key1 == "fontColor") {
              if (this.commonFontFormat[key][key1] == "default") {
                this.commonFontFormat[key][key1] =
                  mirror_background_setting.fontColor;
              }
            }
          });
        });

        // Ensure all keys in fontFormat are present in commonFontFormat
        this.fontFormat.forEach((format) => {
          if (!this.commonFontFormat.hasOwnProperty(format.prefix)) {
            this.commonFontFormat[format.prefix] = this.widgetDefaultValues;
          }
        });

        if (this.bgSettingOptions.widgetname == undefined) {
          this.bgSettingOptions["widgetname"] = "";
        }

        if (this.bgSettingOptions.isMirrorBackgroundSettingEnabled == true) {
          this.selectedBGFormat = "default";
        } else {
          this.selectedBGFormat = "custom";
        }

        if (this.bgSettingOptions.fontColor != "default") {
          this.selectedFontColor = "custom";
        }

        if (this.bgSettingOptions.blur == 0) {
          this.backgroundEffect = "Transparency";
        } else {
          this.backgroundEffect = "Blur";
        }
      }
    }
  }

  updateBgSettingOptionsObject(): void {
    this.bgSettingOptions.widgetTitleFormat = JSON.stringify(this.titleFormat);
    this.bgSettingOptions.widgetFormat = JSON.stringify(this.commonFontFormat);
  }

  onBackgroundOptionEmit() {
    if (this.isDefaultBackgroundColor == false) {
      this.titleFormat.backgroundColor = "default";
    }

    this.bgSettingOptions.widgetTitleFormat = JSON.stringify(this.titleFormat);
    this.bgSettingOptions.widgetFormat = JSON.stringify(this.commonFontFormat);

    this.bgSettingOptions.titleFontColor = "default";

    if (this.selectedFontColor == "default") {
      this.bgSettingOptions.fontColor = "default";
    }

    if (this.selectedBGFormat == "default") {
      this.bgSettingOptions.isMirrorBackgroundSettingEnabled = true;
    } else {
      this.bgSettingOptions.isMirrorBackgroundSettingEnabled = false;
    }

    if (this.backgroundEffect == "Transparency") {
      this.bgSettingOptions.blur = 0;
    } else {
      this.bgSettingOptions.transparency = 0;
    }

    if (this.bgSettingOptions.widgetname.trim() == "") {
      this.bgSettingOptions.isNameVisible = false;
    }

    if (this.widgetType.toLowerCase() === "calendar") {
      this.emitbgsettingCalenderOptions.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "clock") {
      this.emitbgsettingOptions.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "weather") {
      this.emitbgsettingWeatherOptions.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "news") {
      this.emitbgsettingNewsOptions.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "quotes") {
      this.emitbgsettingQuotesOptions.emit(this.bgSettingOptions);
    } else if (
      this.widgetType.toLowerCase() === "stickynotes" ||
      this.widgetType.toLowerCase() === "notes"
    ) {
      this.bgSettingOptions.widgetFormat = "{}";
      this.emitbgsettingNoteOptions.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "health") {
      this.emitbgsettingHealthOptions.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "image") {
      this.emitbgsettingImageOptions.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "video") {
      this.emitbgsettingVideoOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "google_map") {
      this.emitbgsettingGoogleMapOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "google_doc") {
      this.emitbgsettingGoogleDocOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "microsoft_office_doc") {
      this.emitbgsettingMicrosoftDocOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "asana") {
      this.emitbgsettingAsanaOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "trello") {
      this.emitbgsettingTrelloOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "airtable") {
      this.emitbgsettingAirtableOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "embed_website") {
      this.emitbgsettingEmbedWebsiteOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "embed_html") {
      this.emitbgsettingEmbedHtmlOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "pdf") {
      this.emitbgsettingPdfOption.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "todo") {
      this.emitbgsettingTodo.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "count_down") {
      this.emitbgsettingCountDown.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "chores") {
      this.emitbgsettingChores.emit(this.bgSettingOptions);
    } else if (this.widgetType.toLowerCase() === "mealplan") {
      this.emitbgsettingMealPlanOptions.emit(this.bgSettingOptions);
    }
  }

  dismissModel() {
    this.closeModalEvent.emit(true);
  }
}
