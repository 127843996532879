import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LocalStorageService } from "angular-web-storage";
import { Router } from "@angular/router";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  response: any;

  constructor(private storage: LocalStorageService, private route: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({
      accept: "application/json",
      "accept-language": "en-US, en; q = 0.8",
      source: "webApp",
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });
    request = request.clone({ headers });
    if (
      !request.url.includes("/logIn") &&
      !request.url.includes("/signUp") &&
      this.storage.get("userDetails") !== null
    ) {
      let token = this.storage.get("userDetails").authToken;
      request = request.clone({
        headers: request.headers.set("authtoken", token),
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error.error.message === "Invalid User Token.") {
          this.storage.clear();
          this.route.navigate(["login"]);
        } else {
          if (error.error.error.message === "Mirror not registered") {
            error.error.error.message =
              "Unable to register device. Please check that the Display Device Code is entered correctly";
          }
          return throwError(error.error);
        }
        /** Error actions */
      })
    );
  }
}
